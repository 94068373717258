.header-wrapper {
  width: 100%;
  height: 3.2rem;
  position: fixed;
  background-color: #662e91;
  color: #ffffff;

  .header {
    h6 {
      font-size: 1.1rem;
      font-weight: bold;
      margin: 0;
    }

    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
