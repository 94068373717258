@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap");

body {
  margin: 0;
  width: 100%;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.elevated {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
  rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.container {
  max-width: 1920px;
  margin: auto;
  padding: 0 1rem;
}

.space {
  padding: 1rem;
}

.side-space {
  padding: 0 1rem;
}

.count-tag {
  margin-left: 1rem;
  animation: fadein 0.5s linear;
}

@keyframes fadein {
  0% {
    margin-left: 0.5rem;
    opacity: 0;
  }
  100% {
    margin-left: 1rem;
    opacity: 1;
  }
}