.dashboard {
  padding-top: 3.2rem;

  .menu {
    width: 100%;
    height: calc(100vh - 3.2rem);
    overflow: hidden;
    user-select: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 1rem;

    .item {
      font-weight: normal;
      font-size: 0.85rem;
    }
  }

  .dashboard-window {
    height: calc(100vh - 3.2rem);
    width: 100%;
    // overflow-y: scroll;
    // overflow-x: hidden;
    overflow: auto;

    .pagination {
      float: right;
      padding: 1rem;
    }

    .sub-module {
      min-height: 100vh;
      padding: 1rem;
      background-color: #f1f1f1;

      .window-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    .uid {
      max-width: 15vw;
      width: 100%;
    }
  }
}
